<template>
  <app-modal-copy-master-business
    v-if="editMode"
    :show="showModalCopyBusiness"
    @copy-business="copyBusiness"
    @show="showModalCopyBusiness = $event"
    show_info="representative"
  />
  <div class="lg:w-1/2 w-full mb-5">
    <div class="flex space-x-3">
      <h1 class="text-2xl font-bold">
        {{ $t("business.representative.title") }}
      </h1>
      <app-icon
        v-if="editMode"
        name="ClipboardCopyIcon"
        class="w-6 text-gray-400 cursor-pointer"
        @click="showModalCopyBusiness = true"
      />
    </div>
    <p class="mt-4">
      {{ $t("business.representative.description") }}
    </p>
  </div>
  <div class="w-full">
    <form
      @submit.prevent="submitBusinessRepresentative"
      v-if="business.representative != undefined"
    >
      <div class="flex flex-col lg:w-7/12 w-full mb-5">
       <app-form-input
          id="business.representative.first_name"
          name="business.representative.first_name"
          type="text"
          :placeholder="$t('business.representative.first_name_placeholder')"
          :labelText="$t('business.representative.legal_name')"
          v-model="business.representative.first_name"
          :errorMessage="errors.first_name"
          :disabled="!editMode"
        />
        <app-form-input
          id="business.representative.last_name"
          name="business.representative.last_name"
          type="text"
          :placeholder="$t('business.representative.last_name_placeholder')"
          v-model="business.representative.last_name"
          :errorMessage="errors.last_name"
          :disabled="!editMode"
        />
      </div>

      <div class="flex flex-col lg:w-7/12 w-full mb-5">
        <app-form-input
          id="business.representative.email"
          name="business.representative.email"
          type="email"
          :labelText="$t('business.representative.email')"
          v-model="business.representative.email"
          :errorMessage="errors.email"
          :disabled="!editMode"
        />
      </div>

      <div class="flex flex-col lg:w-7/12 w-full mb-5">
        <app-form-input
          id="business.representative.designation"
          name="business.representative.designation"
          type="text"
          :placeholder="$t('business.representative.job_position_placeholder')"
          :labelText="$t('business.representative.designation')"
          v-model="business.representative.designation"
          :errorMessage="errors.designation"
          :disabled="!editMode"
        />
      </div>

      <div class="lg:w-7/12 w-full mb-5">
        <app-form-input
          type="date"
          class="w-full"
          inputclass="rounded-t-md"
          :labelText="$t('business.representative.date_of_birth')"
          :placeholder="$t('business.representative.date_placeholder')"
          v-model="business.representative.date_of_birth"
          :errorMessage="errors.date_of_birth"
          :disabled="!editMode"
        />
      </div>

      <div class="lg:w-7/12 w-full mb-1">
        <app-form-input
          id="business.representative.address_1"
          name="business.representative.address_1"
          type="text"
          :placeholder="$t('business.representative.address_placeholder_1')"
          :labelText="$t('business.representative.home_address')"
          v-model="business.representative.address_1"
          :errorMessage="errors.address_1"
          :disabled="!editMode"
        />
      </div>

      <div class="flex flex-col lg:w-7/12 w-full mb-1">
        <app-form-input
          id="business.representative.address_2"
          name="business.representative.address_2"
          type="text"
          :placeholder="$t('business.representative.address_placeholder_2')"
          v-model="business.representative.address_2"
          :errorMessage="errors.address_2"
          :disabled="!editMode"
        />
      </div>

      <div class="lg:w-7/12 w-full mb-1">
        <app-form-input
          id="business.representative.postcode"
          name="business.representative.postcode"
          type="number"
          :placeholder="$t('business.representative.postcode_placeholder')"
          v-model="business.representative.postcode"
          :errorMessage="errors.postcode"
          :disabled="!editMode"
          :numberOnly="true"
        />
      </div>

      <div class="lg:w-7/12 w-full mb-1">
        <app-form-input
          id="business.representative.city"
          name="business.representative.city"
          type="text"
          :placeholder="$t('business.representative.city_placeholder')"
          v-model="business.representative.city"
          :errorMessage="errors.city"
          :disabled="!editMode"
        />
      </div>

      <app-form-country
        :allowFilter="true"
        :showLabel="false"
        :placeholder="$t('general.country')"
        class="lg:w-7/12 w-full mb-1"
        v-model="business.representative.country"
        :errorMessage="errors.country_id"
        :disabled="!editMode"
      />

      <app-form-state
        :showLabel="false"
        :placeholder="$t('general.address.state')"
        :country_id="business.representative.country?.id ?? 0"
        class="lg:w-7/12 w-full mb-5"
        v-model="business.representative.state"
        :errorMessage="errors.state_id"
        :disabled="!editMode"
      />

      <app-form-telephone-no
        name="business.representative.phone_no"
        :labelText="$t('business.representative.phone_no')"
        class="lg:w-7/12 w-full mb-6"
        v-model="business.representative.phone_no"
        :errorMessage="errors.phone_no"
        :disabled="!editMode"
      />

      <div class="lg:w-7/12 w-full mb-3 text-sm">
        <label class="inline-flex items-center">
          <input
            type="radio"
            class="form-radio focus:ring-transparent"
            @change="onSelected($event)"
            name="business.representative.identification_type"
            value="MyKad"
            v-model="business.representative.identification_type"
            :disabled="!editMode"
            checked
          />
          <span class="ml-2">{{ $t("business.representative.mykad") }}</span>
        </label>
        <label class="inline-flex items-center ml-6">
          <input
            type="radio"
            class="form-radio focus:ring-transparent"
            @change="onSelected($event)"
            name="business.representative.identification_type"
            value="Passport"
            v-model="business.representative.identification_type"
            :disabled="!editMode"
          />
          <span class="ml-2">{{ $t("business.representative.passport") }}</span>
        </label>
      </div>

      <div class="flex flex-col lg:w-7/12 w-full mb-10">
        <app-form-input
          id="business.representative.identification"
          name="business.representative.identification"
          :labelText="labelIdentification()"
          v-model="business.representative.identification"
          :errorMessage="errors.identification"
          :type="ic_type_input.type"
          :maxlength="ic_type_input.maxlength"
          :disabled="!editMode"
          :numberOnly="ic_type_input.type === 'number'"
        />
      </div>

      <app-button
        v-if="editMode"
        type="submit"
        :loading="loading"
        class="lg:w-7/12 w-full"
        :showProceedIcon="true"
        >{{ $t("general.next") }}</app-button
      >
    </form>
  </div>
</template>

<script>
import BUSINESS_STATUS from "@/utils/const/business_status";
export default {
  data() {
    return {
      BUSINESS_STATUS: BUSINESS_STATUS,
      showModalCopyBusiness: false,
      ic_type: "MyKad",
      ic_type_input: null,
      emptyErrors: {
        full_name: [],
        email: [],
        date_of_birth: [],
        address_1: [],
        address_2: [],
        postcode: [],
        city_id: [],
        country_id: [],
        state_id: [],
        phone_no: [],
        identification_type: [],
        identification: [],
      },
    };
  },
  mounted() {
    if (this.business.id == null) {
      this.$router.push({ path: "/master-business/business" });
      return;
    }
    this.selectInputType();
  },
  computed: {
    loading() {
      return this.$store.getters["masterBusinessStore/loading"];
    },

    business() {
      return this.$store.getters["masterBusinessStore/business"];
    },

    errors() {
      return (
        this.$store.getters["masterBusinessStore/errors"] ?? this.emptyErrors
      );
    },

    editMode() {
      return (
        this.business.business_status_id == BUSINESS_STATUS.CREATED ||
        this.business.business_status_id == BUSINESS_STATUS.AMENDED
      );
    },
  },
  watch: {
    ic_type() {
      this.labelIdentification();
    },
  },
  methods: {
    copyBusiness(business) {
      let representative = business.representative;

      representative.id = this.business?.representative?.id ?? null;
      representative.business_id = this.business?.id ?? null;
      representative.full_name = business.representative.full_name;
      representative.email = business.representative.email;
      representative.designation = business.representative.designation;
      representative.date_of_birth = business.representative.date_of_birth;
      representative.address_1 = business.representative.address_1;
      representative.address_2 = business.representative.address_2;
      representative.postcode = business.representative.postcode;
      representative.city = business.representative.city;
      representative.state = business.representative.state;
      representative.phone_no = business.representative.phone_no;
      representative.identification_type =
        business.representative.identification_type;
      representative.identification = business.representative.identification;

      this.business.representative = representative;
    },

    onSelected(event) {
      this.ic_type = event.target.value;
      this.business.representative.identification = null;
      this.selectInputType();
    },
    selectInputType() {
      switch (this.ic_type) {
        case "Passport":
          this.ic_type_input = {
            type: "text",
            maxlength: null,
          };
          break;
        case "MyKad":
        default:
          this.ic_type_input = {
            type: "number",
            maxlength: 12,
          };
      }
    },
    labelIdentification() {
      return this.ic_type == "MyKad"
        ? this.$t("business.representative.mykad")
        : this.$t("business.representative.passport");
    },
    async submitBusinessRepresentative() {
      this.resetState();

      this.business.representative.country_id =
        this.business.representative.country?.id;
      this.business.representative.state_id =
        this.business.representative.state?.id;

      const businessRepresentative = await this.$store.dispatch(
        "masterBusinessStore/createOrUpdateMasterBusinessRepresentative",
        this.business.representative
      );

      if (businessRepresentative == null) return;

      this.$router.push({
        path: "/master-business/business/owners",
        query: {
          business_id: this.business.id,
        },
      });
    },
    resetState() {
      this.$store.commit("masterBusinessStore/resetState", {
        errors: this.emptyErrors,
        message: null,
      });
    },
  },
};
</script>

<style scoped>
.form-radio:checked {
  @apply bg-primary;
}
</style>
